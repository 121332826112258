import { lazy, Suspense, useEffect } from 'react';
import { Navigate, Route, RouteObject, Routes, useParams } from 'react-router-dom';
import useCheckoutState from '@/store/store';
import i18n from '@/utils/i18n';

const CheckoutLayout = lazy(() => import('@/layouts/CheckoutLayout'));
const DefaultLayout = lazy(() => import('@/layouts/DefaultLayout.tsx'));
const CheckoutNotFound = lazy(() => import('@/pages/CheckoutNotFound'));
const Expired = lazy(() => import('@/pages/Expired.tsx'));
const Home = lazy(() => import('@/pages/Home.tsx'));
const HomeAdmission = lazy(() => import('@/pages/HomeAdmission'));
const NextPaymentStep = lazy(() => import('@/components/checkout/NextPaymentStep'));
const PaymentMethod = lazy(() => import('@/components/checkout/PaymentMethod'));
const PaymentSuccess = lazy(() => import('@/components/checkout/PaymentSuccess'));
const Spinner = lazy(() => import('@/components/loader'));

const PreProcessRoute = ({ element }: { element: JSX.Element }) => {
  const { data, setVParams } = useCheckoutState();
  const params = useParams();

  useEffect(() => {
    if (Object.keys(params).length === 0) return;

    setVParams(params);
  }, [params]);

  if (!data) {
    return <Spinner />;
  }

  return element;
};

const OldRouteRedirect = () => {
  const { data } = useCheckoutState();

  useEffect(() => {
    if (!data?.user_detected_locale) return;

    i18n.changeLanguage(data.user_detected_locale);
  }, [data]);

  return <Navigate to={`/order/${data.user_detected_locale}/${data.order.token}/payment-method`} replace={true} />;
};

const AllRoutes = () => {
  const landingChildren: RouteObject[] = [
    {
      path: `checkout-page`,
      element: (
        <Suspense fallback={<Spinner />}>
          <Home />
        </Suspense>
      ),
    },
    {
      path: `checkout-page/admission`,
      element: (
        <Suspense fallback={<Spinner />}>
          <HomeAdmission />
        </Suspense>
      ),
    },
    {
      path: `checkout-one-to-one`,
      element: (
        <Suspense fallback={<Spinner />}>
          <Home oneToOne={false} />
        </Suspense>
      ),
    },
    {
      path: `offer-expired`,
      element: (
        <Suspense fallback={<Spinner />}>
          <Expired />
        </Suspense>
      ),
    },
    {
      path: `en/*`,
      element: (
        <Suspense fallback={<Spinner />}>
          <Home />
        </Suspense>
      ),
    },
    {
      path: `payment-success`,
      element: (
        <Suspense fallback={<Spinner />}>
          <PaymentSuccess />
        </Suspense>
      ),
    },
  ];

  const orderChildren: RouteObject[] = [
    {
      path: `:order_tk`,
      children: [
        {
          path: ``,
          element: <Navigate to={`payment-method`} />,
        },
        {
          path: `payment-success`,
          element: (
            <Suspense fallback={<Spinner />}>
              <PreProcessRoute element={<PaymentSuccess />} />
            </Suspense>
          ),
        },
        {
          path: `payment-method`,
          element: (
            <Suspense fallback={<Spinner />}>
              <PreProcessRoute element={<PaymentMethod />} />
            </Suspense>
          ),
        },
        {
          path: `next-step`,
          element: (
            <Suspense fallback={<Spinner />}>
              <PreProcessRoute element={<NextPaymentStep />} />
            </Suspense>
          ),
        },
      ],
    },
  ];

  const admissionChildren: RouteObject[] = [
    {
      path: `checkout-page`,
      element: (
        <Suspense fallback={<Spinner />}>
          <HomeAdmission />
        </Suspense>
      ),
    },
    {
      path: `offer-expired`,
      element: (
        <Suspense fallback={<Spinner />}>
          <Expired />
        </Suspense>
      ),
    },
    {
      path: `en/*`,
      element: (
        <Suspense fallback={<Spinner />}>
          <Home />
        </Suspense>
      ),
    },
  ];

  const routes: RouteObject[] = [
    {
      path: "/landing/",
      element: (
        <Suspense fallback={<Spinner />}>
          <DefaultLayout />
        </Suspense>
      ),
      children: landingChildren,
    },
    {
      path: "/landing/:lang",
      element: (
        <Suspense fallback={<Spinner />}>
          <DefaultLayout />
        </Suspense>
      ),
      children: landingChildren,
    },
    {
      path: "/order/",
      element: (
        <Suspense fallback={<Spinner />}>
          <CheckoutLayout />
        </Suspense>
      ),
      children: orderChildren,
    },
    {
      path: "/order/:lang",
      element: (
        <Suspense fallback={<Spinner />}>
          <CheckoutLayout />
        </Suspense>
      ),
      children: orderChildren,
    },
    {
      path: "/admission/",
      element: (
        <Suspense fallback={<Spinner />}>
          <DefaultLayout isAdmission={true} />
        </Suspense>
      ),
      children: admissionChildren,
    },
    {
      path: "/admission/:lang",
      element: (
        <Suspense fallback={<Spinner />}>
          <DefaultLayout isAdmission={true} />
        </Suspense>
      ),
      children: admissionChildren,
    },
    {
      path: `:route/:product_tk/:user_tk*`,
      element: (
        <Suspense fallback={<Spinner />}>
          <PreProcessRoute element={<OldRouteRedirect />} />
        </Suspense>
      ),
    },
    {
      path: "*",
      element: (
        <Suspense fallback={<Spinner />}>
          <CheckoutNotFound />
        </Suspense>
      ),
    },
  ];

  const renderRoutes = (routesFiles: RouteObject[]) => {
    return routesFiles?.map((route, index) => {
      const { path, element, children } = route;

      if (children) {
        return (
          <Route key={index} path={path} element={element}>
            {renderRoutes(children)}
          </Route>
        );
      } else {
        return <Route key={index} path={path} element={element} />;
      }
    });
  };

  return <Routes>{renderRoutes(routes)}</Routes>;
};

export default AllRoutes;